import React from 'react';
import { Heading } from '@charlietango/ui';
import { rem } from 'polished';

import { SchemaFrontendUserDataViewModel } from '../../api/api-schema';
import { ElectionType, electionTypeMap } from '../../api/api-types';
import { useParties } from '../../api/hooks/useParties';
import { useDictionary } from '../../hooks/useDictionary';
import { Colors } from '../../styles/colors';
import { TextVariant } from '../../styles/typography';
import { MotionAnchor } from '../Anchor/Anchor';
import Arrow from '../Arrow/Arrow';
import Logo from '../Logo/Logo';
import { DeclarationOverviewViewModel } from './types/DeclarationOverviewViewModel';

export type DeclarationOverviewProps = DeclarationOverviewViewModel & {
  user?: SchemaFrontendUserDataViewModel;
  className?: string;
};

type DeclarationLinkProps = DeclarationOverviewViewModel & {
  election: ElectionType;
  user?: SchemaFrontendUserDataViewModel;
};

function DeclarationLink({
  election,
  user,
  partiesUrl = '/partierne',
  partyDetailsUrl = '/om-partiet',
}: DeclarationLinkProps) {
  const { t } = useDictionary();
  const { data } = useParties();
  const declaration = user?.userDeclarations?.find(
    (item) => item.electionType === election,
  );

  const party = data?.items?.find((party) => party.id === declaration?.partyId);
  const electionKey = electionTypeMap[election];

  return (
    <MotionAnchor
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        bg: Colors.InfoGreen,
        boxShadow: '0 1px 0 0 #cce8e7, inset 0 1px 0 0 #cce8e7',
        textDecoration: 'none',
        p: 5,
      }}
      whileHover="hover"
      href={
        party
          ? `${partyDetailsUrl}?election=${electionTypeMap[election]}&party=${party.id}`
          : `${partiesUrl}?election=${electionTypeMap[election]}`
      }
    >
      <span sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <strong
          css={{
            fontWeight: 500,
            letterSpacing: '1.2px',
            fontSize: rem(13),
            textTransform: 'uppercase',
          }}
        >
          {t(electionKey + 'Election')}:{' '}
        </strong>
        <span css={{ fontSize: rem(15), whiteSpace: 'nowrap' }}>
          {party ? party.name : t('myDeclaration.noElection')}
        </span>
      </span>
      <Arrow sx={{ flex: '0 0 auto' }} />
    </MotionAnchor>
  );
}

function DeclarationOverview({
  className,
  user,
  ...props
}: DeclarationOverviewProps) {
  const { t } = useDictionary();
  if (!user) return null;

  return (
    <section className={className}>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: rem(30),
          mb: 7,
        }}
      >
        <Logo css={{ height: rem(35), width: rem(35) }} animateIn />
        <Heading as="h2" variant={TextVariant.H3} sx={{ ml: 3 }}>
          {t('myDeclaration.title')}
        </Heading>
      </div>
      <DeclarationLink
        user={user}
        election={ElectionType.Folketingsvalg}
        {...props}
      />
      <DeclarationLink
        user={user}
        election={ElectionType.EuropaParlamentsvalg}
        {...props}
      />
    </section>
  );
}

export default DeclarationOverview;

import { CancelledError } from 'react-query';

/**
 * Make sure the order here matches the CollectionState.cs
 * See: https://dev.azure.com/charlietango-ve/V%C3%A6lgererkl%C3%A6ringer/_git/VE%20Core?path=%2FVeCore.Entities%2FEnums%2FCollectionState.cs
 * */
import { SchemaProblemDetails } from './api-schema';

export enum CollectionState {
  running,
  locked,
  approved,
  noCollection,
  deactivated,
}

/**
 * Map the Enum value (the number) to a string value.
 * */
export const collectionStateMap = {
  [CollectionState.running]: 'running',
  [CollectionState.locked]: 'locked',
  [CollectionState.approved]: 'approved',
  [CollectionState.noCollection]: 'noCollection',
  [CollectionState.deactivated]: 'deactivated',
};

/**
 * Make sure the order here matches the ElectionType.cs
 * See: https://dev.azure.com/charlietango-ve/V%C3%A6lgererkl%C3%A6ringer/_git/VE%20Core?path=%2FVeCore.Entities%2FEnums%2FElectionType.cs
 * */
export enum ElectionType {
  Folketingsvalg = 0,
  EuropaParlamentsvalg = 1,
}

/**
 * Map the Enum value (the number) to a string value.
 * */
export const electionTypeMap = {
  [ElectionType.Folketingsvalg]: 'dk',
  [ElectionType.EuropaParlamentsvalg]: 'eu',
};

export interface IError extends CancelledError {
  message?: string;
  status?: number;
  pathname?: string;
  details?: SchemaProblemDetails;
  response?: Object;
  request?: Object;
}
